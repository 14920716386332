<template>
  <v-layout>
    <v-navigation-drawer v-model="drawer" temporary location="right" class="bg-first text-white">
      <v-list-item title="Baranimes" class="font-medium h-[48px]">
        <template v-slot:append>
          <svg
            @click="drawer = !drawer"
            class="cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="m12 13.4l-2.9 2.9q-.275.275-.7.275t-.7-.275q-.275-.275-.275-.7t.275-.7l2.9-2.9l-2.9-2.875q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l2.9 2.9l2.875-2.9q.275-.275.7-.275t.7.275q.3.3.3.713t-.3.687L13.375 12l2.9 2.9q.275.275.275.7t-.275.7q-.3.3-.712.3t-.688-.3L12 13.4Z" />
          </svg>
        </template>
      </v-list-item>
      <v-divider></v-divider>
      <v-list density="compact" nav class="bg-first text-white">
        <router-link to="/">
          <v-list-item prepend-icon="mdi-forum" :title="$t('header.menu.amq')" value="bta"></v-list-item>
        </router-link>
        <router-link to="/anime" @click="global.animesSuggestIndex = 0">
          <v-list-item prepend-icon="mdi-forum" :title="$t('header.menu.animes')" value="anime"></v-list-item>
        </router-link>
        <router-link v-if="auth.user.role != 'guest'" to="/profile">
          <v-list-item prepend-icon="mdi-forum" :title="$t('header.menu.lists')" value="list"></v-list-item>
        </router-link>
        <v-list-item
          v-else
          prepend-icon="mdi-forum"
          :title="$t('header.menu.lists')"
          value="connect"
          @click="signupToggleModal(1)"></v-list-item>
        <a href="https://discord.gg/ASCewh5BZ6" target="_blank">
          <v-list-item prepend-icon="mdi-forum" title="Discord" value="discord"></v-list-item>
        </a>
        <a href="https://www.tiktok.com/@baranimes" target="_blank">
          <v-list-item prepend-icon="mdi-forum" title="TikTok" value="tiktok"></v-list-item>
        </a>
        <v-menu class="rounded-md">
          <template v-slot:activator="{ props }">
            <v-btn class="text-capitalize text-white px-0" v-bind="props">
              <v-img
                :src="'/images/global/' + selectedLanguage + '.png'"
                :alt="selectedLanguage"
                width="24"
                height="24"
                class="rounded-lg"></v-img>
              <v-icon small right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list class="bg-first text-white">
            <v-list-item v-for="(item, index) in langs" :key="index" :value="index">
              <a :href="'/' + item.iso + $route.fullPath" class="flex">
                <v-img :src="item.icon" width="24" class="rounded-lg max-w-[24px] mr-2"></v-img>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </a>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list>
    </v-navigation-drawer>
  </v-layout>
  <header class="flex justify-center items-center sticky z-40 top-0 sm:top-[12px] md:mb-[28px]" id="header">
    <ModalReuse2 :modal-active="global.signIn" @close-modal="toggleModal"
      ><div class="w-full h-full flex items-center justify-center">
        <section @click.stop class="flex items-center justify-center cursor-auto mx-2 w-full max-w-[355px]">
          <div class="bg-first p-5 flex rounded-2xl shadow-lg w-full">
            <div class="px-5 w-full">
              <h2 class="text-2xl font-bold">{{ $t("header.modal.log_in.login") }}</h2>
              <form class="mt-6" @submit.prevent="signIn">
                <div>
                  <label class="block text-gray-300">{{ $t("header.modal.log_in.username") }}</label>
                  <input
                    type="text"
                    :placeholder="$t('header.modal.log_in.username_or_email')"
                    class="w-full px-4 py-3 rounded-lg bg-second mt-2 focus:outline-none text-white"
                    autocomplete
                    v-model="login"
                    required />
                </div>

                <div class="mt-4">
                  <label class="block text-gray-300">{{ $t("header.modal.log_in.password") }}</label>
                  <input
                    placeholder="**************"
                    type="password"
                    class="w-full px-4 py-3 rounded-lg bg-second mt-2 focus:outline-none text-white"
                    v-model="password"
                    required />
                </div>
                <p class="text-red-700" v-if="errorMDP">{{ $t("header.modal.sign_up.error.pseudo") }}</p>
                <div class="text-right mt-2">
                  <button
                    type="button"
                    class="text-sm font-semibold text-gray-300"
                    @click="
                      toggleModal(0);
                      signPasswordToggleModal(1);
                    ">
                    {{ $t("header.modal.log_in.forgot_password") }}
                  </button>
                </div>

                <button type="submit" class="w-full block button-reverse font-semibold rounded-lg px-4 py-3 mt-6">
                  {{ $t("header.modal.log_in.log_in") }}
                </button>
              </form>

              <div class="mt-7 grid grid-cols-3 items-center text-gray-500">
                <hr class="border-gray-500" />
                <p class="text-center text-sm text-gray-500">{{ $t("header.modal.log_in.or") }}</p>
                <hr class="border-gray-500" />
              </div>

              <div class="text-sm flex justify-between items-center mt-3">
                <p>{{ $t("header.modal.log_in.no_account") }}</p>
                <button
                  type="button"
                  @click="
                    toggleModal(0);
                    signupToggleModal(1);
                  "
                  class="button-reverse py-2 px-5 ml-3 rounded-md">
                  {{ $t("header.modal.log_in.sign_up") }}
                </button>
              </div>
            </div>
          </div>
        </section>
      </div></ModalReuse2
    >
    <ModalReuse2 :modal-active="global.signUp" @close-modal="signupToggleModal">
      <div class="container mx-auto cursor-auto">
        <div class="flex justify-center items-center h-screen mx-2">
          <!-- Row -->
          <div
            @click.stop
            class="w-full lg:w-[700px] flex rounded-3xl overflow-hidden bg-first"
            style="box-shadow: rgba(0, 140, 168, 0.4) 0px 15px 20px">
            <!-- Col -->

            <!-- Col -->
            <div class="w-full bg-first p-5">
              <h3 class="pt-4 text-2xl text-center font-medium">{{ $t("header.modal.sign_up.create_an_account") }}</h3>
              <form class="px-4 md:px-8 pt-6 pb-8 mb-4 rounded" @submit.prevent="signUp" v-if="infoSignup">
                <div class="mb-4 md:flex">
                  <div class="mb-4 md:mb-0 w-full">
                    <label class="block mb-2 text-sm font-bold text-white" for="firstName">
                      {{ $t("header.modal.sign_up.username") }}
                    </label>
                    <input
                      class="w-full px-3 py-3 text-sm leading-tight text-white border rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline bg-second"
                      id="firstName"
                      type="text"
                      :placeholder="$t('header.modal.sign_up.username')"
                      required
                      v-model="username" />
                    <p v-if="infoUsername" class="text-red-500">{{ $t("header.modal.sign_up.error.username") }}</p>
                  </div>
                </div>
                <div class="mb-4">
                  <label class="block mb-2 text-sm font-bold text-white" for="email">
                    {{ $t("header.modal.sign_up.email") }}
                  </label>
                  <input
                    class="w-full px-3 py-3 mb-3 text-sm leading-tight text-white border rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline bg-second"
                    id="email"
                    type="email"
                    placeholder="Email"
                    required
                    v-model="email" />
                  <p v-if="infoEmail" class="text-red-500">{{ $t("header.modal.sign_up.error.email") }}</p>
                </div>
                <div class="mb-4">
                  <div class="mb-4 md:mb-0">
                    <label class="block mb-2 text-sm font-bold text-white" for="password">
                      {{ $t("header.modal.sign_up.password") }}
                    </label>
                    <input
                      class="w-full px-3 py-3 mb-3 text-sm leading-tight text-white border rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline bg-second"
                      id="password"
                      type="password"
                      placeholder="******************"
                      required
                      v-model="password1" />
                  </div>
                  <div>
                    <label class="block mb-2 text-sm font-bold text-white" for="c_password">
                      {{ $t("header.modal.sign_up.confirm_password") }}
                    </label>
                    <input
                      class="w-full px-3 py-3 mb-3 text-sm leading-tight text-white border rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline bg-second"
                      id="c_password"
                      type="password"
                      placeholder="******************"
                      required
                      v-model="password2" />
                    <p v-if="infoPassword" class="text-red-500">{{ $t("header.modal.sign_up.error.password") }}</p>
                  </div>
                </div>
                <div class="mb-6 text-center">
                  <button
                    type="submit"
                    class="w-full px-4 py-2 font-bold text-white button-reverse rounded-lg focus:outline-none focus:shadow-outline">
                    {{ $t("header.modal.sign_up.sign_up") }}
                  </button>
                </div>
                <div class="text-center">
                  <span
                    @click="
                      signupToggleModal(0);
                      toggleModal(1);
                    "
                    class="inline-block text-sm align-baseline cursor-pointer">
                    {{ $t("header.modal.sign_up.already_account") }}
                  </span>
                </div>
              </form>
              <div v-else>
                <p>{{ $t("header.modal.sign_up.success") }}</p>
                <p>{{ $t("header.modal.sign_up.connect") }}</p>
                <v-btn
                  class="button-reverse text-white"
                  @click="
                    signupToggleModal(0);
                    toggleModal(1);
                  "
                  >{{ $t("header.modal.log_in.log_in") }}</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalReuse2>
    <ModalReuse2 :modal-active="global.signPassword" @close-modal="signPasswordToggleModal">
      <div class="container mx-auto cursor-auto">
        <div class="flex justify-center items-center h-screen mx-2">
          <div
            @click.stop
            class="w-full lg:w-[700px] flex rounded-3xl overflow-hidden"
            style="box-shadow: rgba(0, 140, 168, 0.4) 0px 15px 20px">
            <div class="w-full bg-second p-5 bg-first">
              <h3 class="pt-4 text-2xl text-center font-medium">
                {{ $t("header.modal.sign_password.forgot_password") }}
              </h3>
              <form class="px-8 pt-6 pb-8 mb-4 rounded" @submit.prevent="signPassword" v-if="!sendEmail">
                <div class="mb-4 t">
                  <label class="block mb-2 text-sm font-bold text-white" for="emailP">
                    {{ $t("header.modal.sign_up.email") }}
                  </label>
                  <input
                    class="w-full px-3 py-2 mb-3 text-sm leading-tight text-white border rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline bg-second"
                    id="emailP"
                    type="email"
                    placeholder="Email"
                    v-model="emailP" />
                </div>
                <div class="text-center">
                  <button
                    type="submit"
                    class="w-full px-4 py-2 font-bold text-white button-reverse rounded-lg focus:outline-none focus:shadow-outline">
                    {{ $t("header.modal.sign_password.reset") }}
                  </button>
                </div>
              </form>
              <div v-else>
                {{ $t("header.modal.sign_password.send") }}
                <br />
                {{ $t("header.modal.sign_password.send2") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalReuse2>
    <div
      class="md:flex sm:mx-8 justify-between md:p-1 w-full sm:rounded-2xl max-w-[1200px] sticky-container md:shadow-3xl">
      <div class="hidden md:flex items-center">
        <router-link to="/" class="relative">
          <img
            src="/images/global/favicon.png"
            alt="Baranimes"
            width="48"
            height="48"
            class="w-10 md:w-12 mr-2 p-[0.1rem] md:p-1" />
          <span
            class="text-xs absolute px-1 top-[1px] right-[-8px] whitespace-nowrap bg-[#f54f67] font-medium rounded-full"
            >BETA</span
          >
        </router-link>
        <div class="hidden lg:flex">
          <router-link to="/">
            <v-btn class="text-white"> {{ $t("header.menu.amq") }} </v-btn>
          </router-link>
          <router-link to="/anime" @click="global.animesSuggestIndex = 0">
            <v-btn class="text-white"> {{ $t("header.menu.animes") }} </v-btn>
          </router-link>
          <router-link v-if="auth.user.role != 'guest'" to="/profile">
            <v-btn class="text-white"> {{ $t("header.menu.lists") }} </v-btn>
          </router-link>
          <v-btn v-else class="text-white" @click="signupToggleModal(1)"> {{ $t("header.menu.lists") }} </v-btn>

          <a href="https://discord.gg/ASCewh5BZ6" target="_blank" class="ml-1">
            <v-btn class="text-white min-w-0 px-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M19.27 5.33C17.94 4.71 16.5 4.26 15 4a.09.09 0 0 0-.07.03c-.18.33-.39.76-.53 1.09a16.09 16.09 0 0 0-4.8 0c-.14-.34-.35-.76-.54-1.09c-.01-.02-.04-.03-.07-.03c-1.5.26-2.93.71-4.27 1.33c-.01 0-.02.01-.03.02c-2.72 4.07-3.47 8.03-3.1 11.95c0 .02.01.04.03.05c1.8 1.32 3.53 2.12 5.24 2.65c.03.01.06 0 .07-.02c.4-.55.76-1.13 1.07-1.74c.02-.04 0-.08-.04-.09c-.57-.22-1.11-.48-1.64-.78c-.04-.02-.04-.08-.01-.11c.11-.08.22-.17.33-.25c.02-.02.05-.02.07-.01c3.44 1.57 7.15 1.57 10.55 0c.02-.01.05-.01.07.01c.11.09.22.17.33.26c.04.03.04.09-.01.11c-.52.31-1.07.56-1.64.78c-.04.01-.05.06-.04.09c.32.61.68 1.19 1.07 1.74c.03.01.06.02.09.01c1.72-.53 3.45-1.33 5.25-2.65c.02-.01.03-.03.03-.05c.44-4.53-.73-8.46-3.1-11.95c-.01-.01-.02-.02-.04-.02M8.52 14.91c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.84 2.12-1.89 2.12m6.97 0c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.83 2.12-1.89 2.12" />
              </svg>
            </v-btn>
          </a>
          <a href="https://www.tiktok.com/@baranimes" target="_blank">
            <v-btn class="text-white min-w-0 px-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M16.6 5.82s.51.5 0 0A4.278 4.278 0 0 1 15.54 3h-3.09v12.4a2.592 2.592 0 0 1-2.59 2.5c-1.42 0-2.6-1.16-2.6-2.6c0-1.72 1.66-3.01 3.37-2.48V9.66c-3.45-.46-6.47 2.22-6.47 5.64c0 3.33 2.76 5.7 5.69 5.7c3.14 0 5.69-2.55 5.69-5.7V9.01a7.35 7.35 0 0 0 4.3 1.38V7.3s-1.88.09-3.24-1.48" />
              </svg>
            </v-btn>
          </a>
        </div>
      </div>
      <div class="lg:hidden md:flex hidden items-center h-[40px] md:h-[48px] pr-1">
        <v-menu class="rounded-md">
          <template v-slot:activator="{ props }">
            <v-btn class="text-capitalize text-white px-0" v-bind="props">
              <v-img
                :src="'/images/global/' + selectedLanguage + '.png'"
                :alt="selectedLanguage"
                width="24"
                height="24"
                class="rounded-lg"></v-img>
              <v-icon small right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list class="bg-first text-white">
            <v-list-item v-for="(item, index) in langs" :key="index" :value="index">
              <a :href="'/' + item.iso + $route.fullPath" class="flex">
                <v-img :src="item.icon" width="24" class="rounded-lg max-w-[24px] mr-2"></v-img>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </a>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-app-bar-nav-icon color="white" @click="searchBar" height="45" width="45">
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M9.5 16q-2.725 0-4.612-1.888T3 9.5q0-2.725 1.888-4.612T9.5 3q2.725 0 4.613 1.888T16 9.5q0 1.1-.35 2.075T14.7 13.3l5.6 5.6q.275.275.275.7t-.275.7q-.275.275-.7.275t-.7-.275l-5.6-5.6q-.75.6-1.725.95T9.5 16Zm0-2q1.875 0 3.188-1.312T14 9.5q0-1.875-1.312-3.187T9.5 5Q7.625 5 6.313 6.313T5 9.5q0 1.875 1.313 3.188T9.5 14Z" />
          </svg>
        </v-app-bar-nav-icon>
        <v-app-bar-nav-icon color="white" @click="toggleModal(1)" height="45" width="45" class="mr-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M12 11.385q-1.237 0-2.119-.882T9 8.385q0-1.238.881-2.12q.881-.88 2.119-.88t2.119.88q.881.882.881 2.12q0 1.237-.881 2.118T12 11.385Zm-7 7.23V16.97q0-.619.36-1.158q.361-.54.97-.838q1.416-.679 2.833-1.018q1.418-.34 2.837-.34q1.42 0 2.837.34q1.417.34 2.832 1.018q.61.298.97.838q.361.539.361 1.158v1.646H5Z" />
          </svg>
        </v-app-bar-nav-icon>
        <v-app-bar-nav-icon color="white" @click.stop="drawer = !drawer" height="45" width="45">
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M4 18q-.425 0-.712-.288T3 17q0-.425.288-.712T4 16h16q.425 0 .713.288T21 17q0 .425-.288.713T20 18H4Zm0-5q-.425 0-.712-.288T3 12q0-.425.288-.712T4 11h16q.425 0 .713.288T21 12q0 .425-.288.713T20 13H4Zm0-5q-.425 0-.712-.288T3 7q0-.425.288-.712T4 6h16q.425 0 .713.288T21 7q0 .425-.288.713T20 8H4Z" />
          </svg>
        </v-app-bar-nav-icon>
      </div>
      <div
        id="mouseOverAnime"
        class="flex items-center lg:mx-0 left-0 absolute top-[8px] md:top-[60px] lg:static lg:w-fit sm:mx-8 mx-4 calc2rem calc4rem lg:visible invisible pt-1 md:p-0">
        <div @click="searchFocusOut(1)" class="flex-1 lg:ml-4 lg:max-w-sm lg:relative h-[calc(100vh - 50px)] lg:h-auto">
          <div
            @click.stop
            class="lg:h-auto"
            id="mouseOverFocus"
            @mouseleave="mouseOverAnime = false"
            @mouseenter="mouseOverAnime = true">
            <v-text-field
              :label="$t('header.menu.search')"
              append-inner-icon="mdi-magnify"
              id="searchAnimeHeader"
              autocomplete="off"
              clearable
              density="compact"
              variant="solo"
              hide-details
              single-line
              :loading="loading"
              @click:clear="
                search = '';
                searchAnimes();
              "
              @input="searchAnimes"
              @keydown="handleKeyDown"
              @focusin="searchFocusIn"
              @focusout="searchFocusOut"
              v-model="search"
              type="search"
              bg-color="#0f112b"
              class="rounded-lg border-none outline-none text-white w-full lg:w-[238px] overflow-hidden shadow-3xl"></v-text-field>

            <div
              class="h-[420px] overflow-auto shadow-3xl bg-first rounded-xl absolute invisible opacity-0 mt-3 w-full lg:w-[272px]"
              id="searchAnimeContainerHeader">
              <div v-if="!visibleAnime" class="flex m-3 justify-center">
                <v-progress-circular :size="50" color="#00bdbf" indeterminate></v-progress-circular>
              </div>
              <v-virtual-scroll
                :class="{ hidden: visibleAnime?.length === 0 }"
                :items="visibleAnime"
                height="420"
                :key="visibleAnime"
                item-height="75"
                id="animeListHeader">
                <template v-slot:default="{ item, index }">
                  <router-link :to="'/anime/' + item.item.url" v-if="item.item.nsfw != 18">
                    <div
                      class="flex cursor-pointer items-center rounded-md m-1 overflow-hidden animeListItem"
                      :class="{ selected: index === selectedIndex }"
                      @click="selectAnime(index, 1)">
                      <v-img
                        loading="lazy"
                        class="rounded-md w-[50px] h-[75px]"
                        :class="{ 'blur-sm': item.item.nsfw == 18 }"
                        :src="'/images/thumbnail/' + item.item.image"
                        :alt="item.item.title"
                        width="50"
                        height="75" />
                      <span class="ml-3 font-medium w-full">{{ item.item.title }}</span>
                    </div>
                  </router-link>
                </template>
              </v-virtual-scroll>
              <div v-if="visibleAnime?.length === 0" class="flex m-3">
                <span class="font-medium w-full text-center">{{ $t("header.menu.no_result_found") }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="auth.user.role == 'guest'" class="hidden lg:flex font-medium ml-1">
          <v-btn class="text-white p-2" @click="toggleModal(1)"> {{ $t("header.menu.log_in") }} </v-btn>
          <v-btn class="text-white p-2" @click="signupToggleModal(1)"> {{ $t("header.menu.sign_up") }} </v-btn>
        </div>
        <div v-else class="hidden lg:flex font-medium">
          <img
            @click="isOpen = !isOpen"
            class="ml-2 cursor-pointer w-12 h-12 p-1 rounded-full"
            :src="'/images/avatar_users/' + auth.user.avatar"
            alt="Avatar" />
          <div class="navbar-right relative">
            <button
              v-if="isOpen"
              @click="isOpen = false"
              tabindex="-1"
              class="fixed top-0 inset-0 h-full w-full bg-black opacity-0 cursor-default"></button>
            <div v-if="isOpen" class="absolute top-[3.25rem] right-0 w-48 py-2 mt-2 rounded-lg bg-[#02041f] shadow-3xl">
              <router-link to="/settings" class="text-sm hover:bg-[#0f112b] px-4 py-2 cursor-pointer flex items-center">
                <Icon name="ic:round-person" size="1.6rem" class="mr-3" />{{
                  $t("header.menu.account_settings")
                }}</router-link
              >
              <span
                v-if="auth.user.role == 'modo' || auth.user.role == 'admin'"
                class="text-sm hover:bg-[#0f112b] px-4 py-2 cursor-pointer flex items-center"
                ><Icon name="material-symbols:admin-panel-settings" size="1.6rem" class="mr-3" />Espace modo</span
              >
              <router-link
                to="/admin"
                v-if="auth.user.role == 'admin'"
                class="text-sm hover:bg-[#0f112b] px-4 py-2 cursor-pointer flex items-center"
                ><Icon name="material-symbols:admin-panel-settings" size="1.6rem" class="mr-3" />Espace
                admin</router-link
              >
              <span @click="signOut" class="text-sm hover:bg-[#0f112b] px-4 py-2 cursor-pointer flex items-center"
                ><Icon name="gridicons:sign-out" size="1.6rem" class="mr-3" />{{ $t("header.menu.sign_out") }}</span
              >
            </div>
          </div>
        </div>
        <v-menu class="rounded-md">
          <template v-slot:activator="{ props }">
            <v-btn class="text-capitalize text-white px-0 hidden lg:flex" v-bind="props">
              <v-img
                :src="'/images/global/' + selectedLanguage + '.png'"
                :alt="selectedLanguage"
                width="24"
                height="24"
                class="rounded-lg"></v-img>
              <v-icon small right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list class="bg-first text-white">
            <v-list-item v-for="(item, index) in langs" :key="index" :value="index">
              <a :href="'/' + item.iso + $route.fullPath" class="flex">
                <v-img :src="item.icon" width="24" class="rounded-lg max-w-[24px] mr-2"></v-img>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </a>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </header>
</template>
<script setup>
  import { ref, onMounted, watch } from "vue";
  import { useAuthStore } from "@/stores/auth";
  import { useAnimesStore } from "@/stores/animes";
  import { useFetch, useWebWorkerFn } from "@vueuse/core";
  import ModalReuse2 from "@/components/ModalReuse2.vue";
  import { useRouter } from "vue-router";
  import { useGlobalStore } from "@/stores/global";
  // import { useI18n } from "vue-i18n";

  // const { t } = useI18n();
  const router = useRouter();
  const auth = useAuthStore();
  const animes = useAnimesStore();
  const global = useGlobalStore();
  const loading = ref(false);
  const $ = (selectors) => document.getElementById(selectors);
  const username = ref("");
  const password = ref("");
  const email = ref("");
  const emailP = ref("");
  const password1 = ref("");
  const password2 = ref("");
  const login = ref("");
  const sendEmail = ref(false);
  const selectedIndex = ref(-1);
  const mouseOverAnime = ref(false);
  const search = ref("");
  const visibleAnime = ref([]);
  const errorMDP = ref(false);
  const isOpen = ref(false);
  const drawer = ref(null);
  const selectedLanguage = ref(localStorage.getItem("lang"));
  const infoSignup = ref(true);
  const infoUsername = ref(false);
  const infoEmail = ref(false);
  const infoPassword = ref(false);

  let timeoutId;
  let animesLocal;
  const langs = ref([
    { title: "English", icon: "/images/global/en.png", iso: "en" },
    { title: "Français", icon: "/images/global/fr.png", iso: "fr" },
  ]);
  onMounted(async () => {
    // let fichierJSON = { id: 1, data: null };
    // let request = indexedDB.open("bdd", 1);
    // request.onupgradeneeded = function (event) {
    //   let db = event.target.result;
    //   if (!db.objectStoreNames.contains("animes")) {
    //     let objectStore = db.createObjectStore("animes", { keyPath: "id" });
    //     objectStore.createIndex("parId", "id", { unique: true });
    //   }
    // };
    // request.onsuccess = function (event) {
    //   let db = event.target.result;
    //   let transaction = db.transaction(["animes"], "readonly");
    //   let objectStore = transaction.objectStore("animes");
    //   let requestGet = objectStore.get(fichierJSON.id);
    //   requestGet.onsuccess = async function () {
    //     if (requestGet.result) {
    //       animes.animes.value = requestGet.result.data;
    //     } else {
    //       const { data: response } = await useFetch("/json/animes.json").get().json();
    //       animes.animes.value = JSON.parse(JSON.stringify(response.value));
    //       let transactionAjout = db.transaction(["animes"], "readwrite");
    //       let objectStoreAjout = transactionAjout.objectStore("animes");
    //       let nouveauFichierJSON = { id: 3, data: response.value };
    //       objectStoreAjout.add(nouveauFichierJSON);
    //     }
    //     transaction.oncomplete = function () {
    //       db.close();
    //     };
    //   };
    // };
    // waitScript();
  });

  watch(
    () => global.pageLoaded,
    async (value) => {
      if (value === true) {
        const { data: version } = await useFetch("/api/list/get", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const r = JSON.parse(version.value);
        const { data: response } = await useFetch("/json/animes.json?v=" + r[0].list)
          .get()
          .json();
        animesLocal = JSON.parse(JSON.stringify(response.value));
        animes.animes.value = animesLocal;
      }
    }
  );
  const toggleModal = (toggle) => {
    if (toggle) {
      global.signIn = true;
    } else {
      global.signIn = false;
    }
  };
  const signupToggleModal = (toggle) => {
    if (toggle) {
      global.signUp = true;
    } else {
      global.signUp = false;
    }
  };
  const signPasswordToggleModal = (toggle) => {
    if (toggle) {
      global.signPassword = true;
    } else {
      global.signPassword = false;
    }
  };
  const signPassword = async () => {
    sendEmail.value = true;
    await useFetch("/api/auth/password", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: emailP.value,
        lang: selectedLanguage.value,
      }),
    });
  };
  const signIn = async () => {
    const { data: response } = await useFetch("/api/auth/cookie", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: login.value,
        password: password.value,
      }),
    });
    if (response.value) {
      location.reload();
    } else {
      errorMDP.value = true;
    }
  };
  const signUp = async () => {
    if (password1.value === password2.value) {
      const { data: response } = await useFetch("/api/auth/register", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username.value,
          password: password1.value,
          email: email.value,
        }),
      });
      if (response.value == "username") {
        infoUsername.value = true;
      } else if (response.value == "email") {
        infoEmail.value = true;
      } else if (response.value == "ok") {
        infoSignup.value = false;
      }
    } else {
      infoPassword.value = true;
    }
  };
  const signOut = () => {
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    location.reload();
  };

  const searchBar = () => {
    $("mouseOverAnime").classList.toggle("invisible");
    $("searchAnimeHeader").focus();
  };
  // const resetItem = () => {
  //   clearTimeout(timeoutId);
  //   loading.value = true;
  //   timeoutId = setTimeout(function () {
  //     loading.value = false;
  //     selectedIndex.value = 0;
  //     $("animeListHeader").scrollTop = 0;
  //   }, 200);
  // };

  const myExpensiveFunction = (animesList, input) => {
    // function normaliserPopularite(classement) {
    //   const minClassement = 1;
    //   const maxClassement = 5000;
    //   const minNormalise = 1;
    //   const maxNormalise = 0;

    //   const populariteNormalisee =
    //     (minNormalise +
    //       ((maxNormalise - minNormalise) * (classement - minClassement)) / (maxClassement - minClassement)) *
    //     2;

    //   return populariteNormalisee;
    // }
    if (input.length && animesList.length) {
      let thres;
      if (input.length < 3) {
        thres = 0.66;
      } else {
        thres = 1 / (input.length / 2);
      }

      // eslint-disable-next-line
      let fuse = new Fuse(animesList, {
        keys: ["title", "alternative"],
        includeScore: true,
        minMatchCharLength: 2,
        threshold: thres,
        fieldNormWeight: 4,
      });
      if (input?.trim() === "") {
        return [];
      } else {
        const result = fuse.search(input?.trim(), { limit: 50 });
        result.sort(function (a, b) {
          let scoreA = 1 - a.score;
          let scoreB = 1 - b.score;
          // scoreA = scoreA + (a.score + 0.1) * normaliserPopularite(5000 - a.item.weigth);
          // scoreB = scoreB + (b.score + 0.1) * normaliserPopularite(5000 - b.item.weigth);

          return scoreB - scoreA;
        });

        return result.slice(0, 50);
      }
    }
    return [];
  };

  const { workerFn, workerTerminate } = useWebWorkerFn(myExpensiveFunction, {
    timeout: 30000,
    dependencies: ["https://cdn.jsdelivr.net/npm/fuse.js/dist/fuse.js"],
  });
  function searchAnimes() {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(async function () {
      loading.value = true;
      if (!animesLocal) {
        setTimeout(function () {
          searchAnimes();
        }, 100);
        return;
      }
      const input = search.value;
      workerTerminate("PENDING");
      visibleAnime.value = await workerFn(animesLocal, input);
      loading.value = false;
      if (search.value) {
        $("searchAnimeContainerHeader").classList.remove("invisible", "opacity-0");
      } else {
        $("searchAnimeContainerHeader").classList.add("invisible", "opacity-0");
      }
    }, 100);
  }
  // const waitScript = () => {
  // if (!animes.animes.value) {
  //   setTimeout(function () {
  //     waitScript();
  //   }, 200);
  //   return;
  // }

  //   let sortedAnime = computed(() => {
  //     const tableauDeNoms = animes.animes.value;

  //     const input = search.value.toLowerCase().trim();

  //     const lettresDeInput = input.toLowerCase().trim().split("");

  //     const filteredArray = tableauDeNoms.filter(function (anime) {
  //       const lettresDuNom = anime.others
  //         .replace(/:space:/g, "")
  //         .toLowerCase()
  //         .trim()
  //         .split("");

  //       return lettresDeInput.every(function (lettre) {
  //         return lettresDuNom.includes(lettre);
  //       });
  //     });
  //     const searchValue = search.value.toLowerCase().trim();

  //     const sorted = filteredArray.slice().sort((a, b) => {
  //       let scoreA = 0;
  //       let scoreB = 0;
  //       const nomsAlternatifsA = a.others.split(":space:");
  //       nomsAlternatifsA.forEach(function (item) {
  //         const results = compareAnime(searchValue, item);
  //         if (results > scoreA) {
  //           scoreA = results;
  //         }
  //       });

  //       const nomsAlternatifsB = b.others.split(":space:");
  //       nomsAlternatifsB.forEach(function (item) {
  //         const results = compareAnime(searchValue, item);
  //         if (results > scoreB) {
  //           scoreB = results;
  //         }
  //       });
  //       const weightedScoreA = scoreA + normaliserPopularite(a.popularity);
  //       const weightedScoreB = scoreB + normaliserPopularite(b.popularity);

  //       return weightedScoreB - weightedScoreA;
  //     });

  //     return sorted.slice(0, 10);
  //   });

  //   visibleAnime.value = computed(() => sortedAnime.value);
  // };
  const searchFocusIn = () => {
    selectedIndex.value = 0;
    $("animeListHeader").scrollTop = 0;
    if (search.value.length) {
      $("searchAnimeContainerHeader").classList.remove("invisible", "opacity-0");
    }
    $("mouseOverAnime").classList.remove("invisible");
    $("searchAnimeHeader").focus();
    // $("mouseOverFocus").style.height = "472px";
  };
  const searchFocusOut = (close) => {
    if (!mouseOverAnime.value || close == 1) {
      $("searchAnimeContainerHeader").classList.add("invisible", "opacity-0");
      $("mouseOverAnime").classList.add("invisible");
      // $("mouseOverFocus").style.height = "0px";
    }
  };

  const selectAnime = (index, reset) => {
    setTimeout(function () {
      const url = visibleAnime.value[index].item.url;
      if (!reset) {
        router.push({ name: "infos", params: { title: url } });
      }
      $("animeListHeader").scrollTop = 0;
      search.value = "";
      document.getElementById("searchAnimeHeader").blur();
      document.getElementById("searchAnimeContainerHeader").classList.add("invisible", "opacity-0");
      $("mouseOverAnime").classList.add("invisible");
    }, 1);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (selectedIndex.value !== -1) {
        selectAnime(selectedIndex.value);
      }
    } else if (event.key === "ArrowDown") {
      if (selectedIndex.value < visibleAnime.value.length - 1) {
        selectedIndex.value++;
        $("animeListHeader").querySelector(".animeListItem.selected").scrollIntoView({ block: "start" });
        event.stopPropagation();
      }
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      if (selectedIndex.value > 0) {
        selectedIndex.value--;
        $("animeListHeader").querySelector(".animeListItem.selected").scrollIntoView({ block: "end" });
        event.stopImmediatePropagation();
      }
    }
  };
</script>
<style scoped>
  .gutter {
    margin-left: -13px;
  }
  @media (max-width: 767px) {
    .gutter #mouseOverAnime {
      visibility: visible !important;
      padding-left: 13px;
    }
  }

  #header {
    transition: background-color 0.3s cubic-bezier(0.2, 0.45, 0, 1), top 0.3s cubic-bezier(0.2, 0.45, 0, 1);
  }
  .sticky-container {
    transition: 0.3s;
    background-color: rgb(2 4 31);
  }
  @media (min-width: 1024px) {
    .lg\:w-fit {
      width: fit-content !important;
    }
  }
  :deep(.v-btn__content) {
    letter-spacing: normal;
    text-transform: capitalize;
    font-size: 1rem;
  }
  :deep(.font-medium .v-list-item-title) {
    font-weight: 600;
  }
</style>
