<template>
  <div class="main overflow-hidden">
    <section class="banner-section-main" id="section-home">
      <div class="banner-section-inner">
        <div class="banner-section-loop">
          <div class="banner-main-img lg:flex hidden">
            <div class="main-img scene opacity-0">
              <img
                src="https://www.baranimes.com/images/avatar_users/64f5bb5d1a2fd9.30522874.png"
                alt="banner-img-1"
                class="layer img-responsive"
                data-depth="0.2"
                style="filter: drop-shadow(rgb(144, 227, 253) 20px 10px 0px)" />
            </div>
            <div class="bg-text-stroke">
              <span>Minori</span>
            </div>
          </div>
          <div
            class="banner-content-main w-full lg:w-[33vw] pl-[8%] md:pl-[15%] lg:pl-0 pr-[8%] lg:pr-0 lg:pt-[70px] relative"
            id="banner-content-main">
            <div id="controller-play" class="controller-play">
              <h5 id="amq" class="valo uppercase whitespace-nowrap">Blind test anime</h5>
              <h1 id="play" class="play valo" @click="play(1)">Jouer</h1>
            </div>
            <div class="controller-icons-main controller-box-second flex-wrap">
              <div class="overflow-auto flex-wrap flex justify-center w-full content-start h-[55vh] max-w-[1100px]">
                <div class="mx-5 mb-6">
                  <p class="text-center font-medium text-xl mb-1">Mode de jeu</p>
                  <div class="flex justify-center">
                    <div class="controller-icons-inner" @click="go">
                      <div class="controller-img">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12z"></path>
                          <circle cx="16" cy="10" r="0" fill="currentColor">
                            <animate
                              attributeName="r"
                              begin=".67"
                              calcMode="spline"
                              dur="1.5s"
                              keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                              repeatCount="indefinite"
                              values="0;1.75;0;0"></animate>
                          </circle>
                          <circle cx="12" cy="10" r="0" fill="currentColor">
                            <animate
                              attributeName="r"
                              begin=".33"
                              calcMode="spline"
                              dur="1.5s"
                              keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                              repeatCount="indefinite"
                              values="0;1.75;0;0"></animate>
                          </circle>
                          <circle cx="8" cy="10" r="0" fill="currentColor">
                            <animate
                              attributeName="r"
                              begin="0"
                              calcMode="spline"
                              dur="1.5s"
                              keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                              repeatCount="indefinite"
                              values="0;1.75;0;0"></animate>
                          </circle>
                        </svg>
                      </div>
                      <p>Standard</p>
                    </div>
                    <div class="controller-icons-inner">
                      <div class="controller-img">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                          <path
                            fill="currentColor"
                            d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3z"></path>
                        </svg>
                      </div>
                      <p>Multichoix</p>
                    </div>
                  </div>
                </div>
                <div class="mx-5 mb-6">
                  <p class="text-center font-medium text-xl mb-1">
                    {{ $t("home.setup.difficulty.title") }}
                  </p>
                  <div class="flex justify-center">
                    <div v-for="difficulty in difficulties" :key="difficulty.value" class="controller-icons-inner">
                      <div class="controller-img">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                          <path fill="currentColor" :d="difficulty.icon" />
                        </svg>
                      </div>
                      <p>{{ difficulty.label }}</p>
                    </div>
                  </div>
                </div>
                <div class="mx-5 mb-6">
                  <p class="text-center font-medium text-xl mb-1">
                    {{ $t("home.setup.songs_number.title") }}
                  </p>
                  <div class="flex">
                    <div class="flex justify-center items-center mb-3 h-[74px] relative">
                      <v-slider
                        v-model="global.number"
                        color="#bbb1e7"
                        class="w-[150px] h-[28px]"
                        min="5"
                        max="100"
                        step="5"></v-slider>
                      <span class="font-medium absolute right-[-30px] pt-[1px] text-[#bbb1e7]">{{
                        global.number
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="mx-5 mb-6">
                  <p class="text-center font-medium text-xl mb-1">
                    {{ $t("home.setup.difficulty.title") }}
                  </p>
                  <div class="flex justify-center">
                    <div v-for="mediaType in mediaTypes" :key="mediaType.value" class="controller-icons-inner">
                      <div class="controller-img">
                        <svg xmlns="http://www.w3.org/2000/svg" :viewBox="mediaType.viewBox">
                          <path fill="currentColor" :d="mediaType.icon" />
                        </svg>
                      </div>
                      <p>{{ mediaType.label }}</p>
                    </div>
                  </div>
                </div>
                <div class="mx-5 mb-6">
                  <p class="text-center font-medium text-xl mb-1">
                    {{ $t("home.setup.guess_time.title") }}
                  </p>
                  <div class="flex">
                    <div class="flex justify-center items-center mb-3 h-[74px] relative">
                      <v-slider
                        v-model="global.time"
                        color="#bbb1e7"
                        class="w-[150px] h-[28px]"
                        min="5"
                        max="60"
                        step="5"></v-slider>
                      <span class="font-medium absolute right-[-30px] pt-[1px] text-[#bbb1e7]">{{ global.time }}s</span>
                    </div>
                  </div>
                </div>
                <div class="mx-5 mb-6">
                  <p class="text-center font-medium text-xl mb-1">
                    {{ $t("home.setup.songs_selection.title") }}
                  </p>
                  <div class="flex justify-center">
                    <div class="controller-icons-inner">
                      <div class="controller-img">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                          <path
                            fill="currentColor"
                            d="M224 128a8 8 0 0 1-8 8h-88a8 8 0 0 1 0-16h88a8 8 0 0 1 8 8Zm-96-56h88a8 8 0 0 0 0-16h-88a8 8 0 0 0 0 16Zm88 112h-88a8 8 0 0 0 0 16h88a8 8 0 0 0 0-16ZM82.34 42.34L56 68.69L45.66 58.34a8 8 0 0 0-11.32 11.32l16 16a8 8 0 0 0 11.32 0l32-32a8 8 0 0 0-11.32-11.32Zm0 64L56 132.69l-10.34-10.35a8 8 0 0 0-11.32 11.32l16 16a8 8 0 0 0 11.32 0l32-32a8 8 0 0 0-11.32-11.32Zm0 64L56 196.69l-10.34-10.35a8 8 0 0 0-11.32 11.32l16 16a8 8 0 0 0 11.32 0l32-32a8 8 0 0 0-11.32-11.32Z" />
                        </svg>
                      </div>
                      <p>{{ $t("home.setup.songs_selection.custom") }}</p>
                    </div>
                  </div>
                </div>
                <div class="mx-5 mb-6">
                  <p class="text-center font-medium text-xl mb-1">
                    {{ $t("home.setup.private_room.title") }}
                  </p>
                  <div class="flex justify-center">
                    <div class="controller-icons-inner">
                      <div class="controller-img">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M6 22q-.825 0-1.413-.588T4 20V10q0-.825.588-1.413T6 8h1V6q0-2.075 1.463-3.538T12 1q2.075 0 3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.588 1.413T18 22H6Zm6-5q.825 0 1.413-.588T14 15q0-.825-.588-1.413T12 13q-.825 0-1.413.588T10 15q0 .825.588 1.413T12 17ZM9 8h6V6q0-1.25-.875-2.125T12 3q-1.25 0-2.125.875T9 6v2Z" />
                        </svg>
                      </div>
                      <p>{{ $t("home.setup.private_room.private") }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="controller-box">
              <div class="controller-box-section">
                <div class="controller-box-content">
                  <h5>Derniers sons ajoutés</h5>
                  <p>
                    A phantom of a memory, Omen hunts in the shadows. He renders enemies blind, teleports across the
                    field, then lets paranoia take hold as his foe scrambles to learn where he might strike next.
                  </p>
                </div>
              </div>
              <div class="controller-icons-main">
                <div class="controller-icons-inner">
                  <router-link to="/testbattle-pass">
                    <div class="controller-img">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path
                          fill="currentColor"
                          d="M256 16C164.8 16-10.99 191.2 56.01 336c32.8 67.2 64.59 89.6 96.99 144h23c-23.7-72.7-108.09-117.2-98.99-190.1C87.41 207.4 176 128 256 112c80 16 168.6 95.4 179 177.9c9.1 72.9-75.3 117.4-99 190.1h23c32.4-54.4 64.2-76.8 97-144C523 191.2 347.2 16 256 16m120 188.4l-81.1 60.8l70.9-10.1zm-239.8.1l10.1 50.6l70.8 10.1zM256 266.7c-39.2 26.1-49.5 30.7-98.6 43.1L256 493l98.6-183.2c-49.1-12.4-59.4-17-98.6-43.1m-23.5 98.5l16 48l-17 5.6l-16-48zm47 0l17 5.6l-16 48l-17-5.6z" />
                      </svg>
                    </div>
                    <p>Avatar</p>
                  </router-link>
                </div>
                <div class="controller-icons-inner">
                  <div class="controller-img">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M21.16 4.258c.033 3.5.59 4.81.787 6.701a9.98 9.98 0 0 1-2.875 8.112c-3.666 3.666-9.471 3.89-13.4.673l2.852-2.853a6.002 6.002 0 0 0 8.368-8.368zm-2.83-.002l-2.852 2.853a6.002 6.002 0 0 0-8.368 8.368l-4.267 4.265c-.034-3.5-.591-4.811-.788-6.701A9.98 9.98 0 0 1 4.93 4.929c3.666-3.666 9.471-3.89 13.4-.673M12 8a4 4 0 1 1 0 8a4 4 0 0 1 0-8m0 2.5a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3" />
                    </svg>
                  </div>
                  <p>Passe de combat</p>
                </div>
                <div class="controller-icons-inner">
                  <div class="controller-img">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path
                        fill="currentColor"
                        d="M255 471L91.7 387V41h328.6v346zm-147.3-93.74L255 453l149.3-75.76V57H107.7zm187.61-168.34l-14.5-46l38.8-28.73l-48.27-.43L256 87.94l-15.33 45.78l-48.27.43l38.8 28.73l-14.5 46l39.31-28zM254.13 311.5l98.27-49.89v-49.9l-98.14 49.82l-94.66-48.69v50zm.13 32.66l-94.66-48.69v50l94.54 48.62l98.27-49.89v-49.9z" />
                    </svg>
                  </div>
                  <p>Classement</p>
                </div>
                <div class="controller-icons-inner">
                  <div class="controller-img">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2s-.9-2-2-2M2 4h1l3.6 7.59l-1.35 2.44C4.52 15.37 5.48 17 7 17h11c.55 0 1-.45 1-1s-.45-1-1-1H7l1.1-2h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49A.996.996 0 0 0 20.01 4H5.21l-.67-1.43a.993.993 0 0 0-.9-.57H2c-.55 0-1 .45-1 1s.45 1 1 1m15 14c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2s2-.9 2-2s-.9-2-2-2" />
                    </svg>
                  </div>
                  <p>Boutique</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="controller-right-icons-main lg:block hidden">
        <div class="controller-right-icons-inner">
          <div>
            <img src="https://www.yudiz.com/codepen/valorant-characters/right-icon-1.svg" alt="" />
          </div>
          <div>
            <img src="https://www.yudiz.com/codepen/valorant-characters/right-icon-3.svg" alt="" />
          </div>
          <div>
            <img src="https://www.yudiz.com/codepen/valorant-characters/right-icon-4.svg" alt="" />
          </div>
          <div>
            <img src="https://www.yudiz.com/codepen/valorant-characters/right-icon-1.svg" alt="" />
          </div>
          <div>
            <img src="https://www.yudiz.com/codepen/valorant-characters/right-icon-3.svg" alt="" />
          </div>
        </div>
      </div>
    </section>
    <section class="banner-section-second w-0" id="banner-section-second">
      <div class="banner-section-loop justify-end" style="align-items: initial">
        <div
          class="banner-content-main w-full lg:w-[40vw] pl-[8%] md:pl-[15%] lg:pl-0 pr-[8%] lg:pr-0 lg:pt-[70px] mr-[8%] mt-[17vh]">
          <div class="controller-play active" style="margin: 0">
            <h5 class="valo uppercase text-center">Rejoindre une partie</h5>
            <h1 class="play valo opacity-0">Jouer</h1>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style scoped>
  @import url("https://fonts.googleapis.com/css2?family=Teko:wght@600&display=swap");

  @keyframes quickPlayButtonEffect {
    100% {
      background-position-x: -200%;
    }
  }
  @keyframes glitch {
    from,
    to {
      transform: translate(0);
    }

    12% {
      transform: translate(-2%, -1%);
    }

    40% {
      transform: translate(-1%, -3%);
    }

    80% {
      transform: translate(-2%, 1%);
    }
  }

  .play {
    user-select: none;
    animation: quickPlayButtonEffect 5s infinite linear;
    background: linear-gradient(
      -60deg,
      #ff8d9e 0%,
      #ff8d9e 10%,
      #f54f67 25%,
      #f54f67 30%,
      #ff8d9e 50%,
      #f54f67 80%,
      #f54f67 85%,
      #ff8d9e 92%
    );
    background-size: 200% 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
  }
  .controller-play {
    transition: transform 0.4s;
  }
  .controller-play.active {
    transition: transform 0.4s;
    transform: translateY(-2vw);
  }
  .controller-play #amq,
  .controller-play #play {
    transition: all 0.8s, scale 0.4s;
  }
  .controller-play.active #amq,
  .controller-play.active #play {
    transition: 0.4s;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  #amq {
    width: fit-content;
  }
  .bg-text-stroke {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    padding-left: 100px;
    z-index: -1;

    span {
      font: 700 21rem "Teko";
      line-height: 0.8;
      text-transform: uppercase;
      -webkit-text-fill-color: rgba(0, 0, 0, 0);
      -webkit-text-stroke-color: rgba(255, 255, 255, 0.3);
      -webkit-text-stroke-width: 1px;
      text-orientation: mixed;
      writing-mode: vertical-rl;
    }
  }

  a {
    text-decoration: none;
    outline: none;
    color: var(--text-color-1);
    cursor: pointer;
  }
  a:hover {
    color: var(--text-color-1);
  }
  a,
  a:active,
  a:focus {
    outline: none;
    text-decoration: none;
  }
  img {
    border: none;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
  ul,
  li {
    padding: 0;
    margin: 0;
  }

  h1 {
    font-size: 100px;
    font-style: normal;
    font-weight: 800;
    line-height: 104px;
    letter-spacing: 5.16px;
    background: linear-gradient(180deg, #f54f67 0%, #988be3 90%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: 0.4s;
  }
  h1:hover {
    scale: 1.05;
  }
  h5 {
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 10.8px;
    color: var(--text-color-5);
    transition: 0.4s;
  }

  /* -- Common Element CSS End -- */

  /* -- Banner Section CSS Start -- */

  .banner-section-second {
    width: 100vw;
    height: 100vw;
    position: absolute;
    top: 0;
    transform: translate(100%);
    transition: transform 0.4s;
  }
  .banner-section-second.active {
    transform: translate(0);
  }
  .banner-section-second .banner-section-loop {
    position: relative;
    padding-top: 42px;
    display: flex;
    align-items: center;
    background: no-repeat top center / 100% 100%;
    width: 100vw;
    height: 100vh;
    gap: 20px;
  }

  .banner-section-main {
    position: relative;
    z-index: 9;
    transition: transform 0.4s;
  }
  .banner-section-main.active {
    transform: translate(-50%);
  }

  .controller-box,
  .controller-right-icons-main {
    transition: transform 0.4s, opacity 0.8s, height 0.4s;
  }
  .banner-section-main.active .controller-box,
  .banner-section-main.active .controller-right-icons-main {
    transition: transform 0.4s, opacity 0.2s;
    opacity: 0;
    pointer-events: none;
  }
  .banner-section-main.active .controller-box {
    transition: transform 0.4s, opacity 0.2s, height 0.4s;
    opacity: 0;
    pointer-events: none;
    transform: translate(-100%);
  }
  .controller-box-second {
    transition: transform 0.6s, opacity 0.2s, height 0.4s;
    opacity: 0;
    height: 0;
    padding: 0 !important;
    transform: translate(100%, -2vw);
  }
  .banner-section-main.active .controller-box-second {
    transition: transform 0.4s, opacity 0.2s, height 0.4s;
    opacity: 1;
    height: auto;
    transform: translate(0, -2vw);
    justify-content: center;
    height: 0;
    align-content: start;
  }
  .banner-section-main.active .banner-main-img {
    transition: transform 0.8s, opacity 0.2s;
    opacity: 0;
  }
  .glitch {
    transition: left 0.6s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.14s ease-out;
    opacity: 1;
    animation: glitch 10s cubic-bezier(0.5, 0, 0.5, 1) infinite alternate;
  }
  .banner-section-main .banner-section-loop {
    position: relative;
    padding-top: 42px;
    display: flex;
    align-items: center;
    background: no-repeat top center / 100% 100%;
    width: 100vw;
    height: 100vh;
    transition: 0.4s;
    gap: 20px;
  }

  .banner-main-img {
    width: 48vw;
    max-height: 865px;
    margin-left: 7.6%;
    transition: transform 0.4s, opacity 0.8s;
    user-select: none;
    align-self: center;
  }

  .controller-right-icons-main {
    width: calc(12% - 15px);
    padding-right: 15px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    z-index: 9999;
  }
  .controller-right-icons-second {
    width: calc(12% - 15px);
    padding-right: 15px;
    position: absolute;
    top: 50%;
    right: -50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    z-index: 9999;
  }
  .controller-right-icons-main img {
    width: 62px;
    height: 62px;
    margin-left: auto;
    margin-bottom: 34px;
    cursor: pointer;
    transition: 0.3s;
    -webkit-transition: 0.3s;
  }
  .controller-right-icons-main .slick-track {
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
  }
  .controller-right-icons-main .slick-slide {
    width: 100%;
  }

  .banner-content-main h5 {
    padding-bottom: 12px;
  }
  #banner-content-main {
    transition: width 0.2s;
    margin-top: 0%;
  }
  #banner-content-main.active {
    transition: width 0.4s;
    width: 58vw !important;
  }
  .banner-section-loop .banner-content-main h1 {
    display: inline-flex;
    padding-bottom: 28px;
  }

  .controller-box-section {
    border: 1px solid var(--text-color-6);
    background: linear-gradient(180deg, rgba(68, 45, 142, 0.2) 0%, rgba(82, 51, 180, 0.09) 100%);
    padding: 19.771px 22.218px 20.229px 22.799px;
    display: flex;
    gap: 15px;
    margin-bottom: 14px;
    border-radius: 15px;
  }
  .controller-box-img {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
  }
  .banner-content-main .controller-box-content h5 {
    letter-spacing: normal;
    padding-bottom: 6px;
  }
  .controller-icons-main {
    display: flex;
    gap: 18px;
    padding-bottom: 45px;
  }
  .controller-icons-inner {
    border: 1px solid var(--text-color-6);
    flex: 1;
    border-radius: 15px;
  }
  .controller-box-second .controller-icons-inner {
    background: linear-gradient(180deg, rgba(68, 45, 142, 0.2) 0%, rgba(82, 51, 180, 0.09) 100%);
    min-width: 100px;
    max-width: 100px;
    margin: 5px;
  }
  .controller-box-second .controller-icons-inner p {
    text-transform: capitalize;
  }
  .controller-box-second {
    color: var(--light-t1);
  }
  .controller-img {
    padding: 18px;
    text-align: center;
    color: var(--light-t1);
  }
  .controller-box-second .controller-img {
    padding: 5px 18px;
    max-width: 80px;
    margin: auto;
  }
  .controller-img img {
    aspect-ratio: 1/1;
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
  .controller-icons-inner p {
    font-size: 14px;
    line-height: 16px;
    color: #bbb1e7;
    text-transform: uppercase;
    text-align: center;
    padding: 3px;
    border-top: 1px solid var(--text-color-6);
    background: linear-gradient(180deg, rgba(68, 45, 142, 0.2) 0%, rgba(82, 51, 180, 0.09) 100%);
  }

  .banner-main-img .main-img {
    position: relative;
    padding-top: 78%;
    width: 100%;
    z-index: 1;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    transform: translate(-50%, 10%) !important;
    -webkit-transform: translate(-50%, 10%) !important;
    left: 70%;
  }
  .banner-main-img .main-img.character-animation {
    animation: character-animation 2s ease-in-out 1;
  }
  .banner-main-img img {
    position: absolute !important;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    height: 100%;
    display: block;
  }

  .controller-right-icons-main .slick-current.slick-active img,
  .controller-right-icons-main .slick-active img:hover {
    filter: drop-shadow(6px 6px 6px var(--text-color-4));
  }
  .controller-right-icons-main .slick-current.slick-active:nth-child(3n + 2) img,
  .controller-right-icons-main .slick-active:nth-child(3n + 2) img:hover {
    filter: drop-shadow(6px 6px 6px var(--text-color-12));
  }
  .controller-right-icons-main .slick-current.slick-active:nth-child(3n + 3) img,
  .controller-right-icons-main .slick-active:nth-child(3n + 3) img:hover {
    filter: drop-shadow(6px 6px 6px var(--text-color-14));
  }

  /* -- Banner Section CSS End -- */

  /* -- Second Slide CSS Start -- */

  .banner-loop-second .banner-content-main h5 {
    color: var(--text-color-7);
  }
  .banner-loop-second .controller-box-content h5 {
    color: var(--text-color-9);
  }
  .banner-loop-second .controller-box-content p {
    color: var(--light-t1);
  }
  .banner-loop-second .controller-box-section {
    border: 1px solid var(--border-color-2);
    background: linear-gradient(180deg, rgba(101, 75, 37, 0.13) 0%, rgba(246, 186, 98, 0.05) 100%);
  }
  .banner-loop-second .controller-icons-inner {
    border: 1px solid var(--border-color-2);
  }
  .banner-loop-second .controller-icons-inner p {
    border-top: 1px solid var(--border-color-2);
    background: linear-gradient(180deg, rgba(130, 99, 55, 0.13) 0%, rgba(221, 170, 95, 0.17) 100%);
    color: var(--text-color-10);
  }

  .banner-loop-second .banner-content-main h1 {
    background: linear-gradient(180deg, var(--text-color-12) 0%, var(--text-color-13) 90%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .banner-loop-second .controller-right-icons-main img {
    filter: hue-rotate(358deg);
    -webkit-filter: hue-rotate(358deg);
  }

  /* -- Second Slide CSS End -- */

  /* -- Third Slide CSS Start -- */

  .banner-loop-third .banner-content-main h5 {
    color: var(--text-color-14);
  }
  .banner-loop-third .banner-content-main h1 {
    background: linear-gradient(180deg, var(--text-color-15) 0%, var(--text-color-14) 90%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .controller-box-content p {
    color: var(--light-t1);
  }
  .banner-loop-third .controller-box-section {
    border: 1px solid var(--text-color-14);
    background: linear-gradient(180deg, rgba(2, 157, 56, 0.08) 0%, rgba(6, 167, 61, 0.05) 100%);
  }
  .banner-loop-third .controller-icons-inner {
    border: 1px solid var(--text-color-16);
  }
  .banner-loop-third .controller-icons-inner p {
    border-top: 1px solid rgba(1, 171, 63, 0);
    background: linear-gradient(180deg, rgba(2, 157, 56, 0.08) 0%, rgba(6, 167, 61, 0.01) 100%);
    color: var(--text-color-14);
  }

  /* -- Third Slide CSS End -- */

  @media only screen and (max-width: 1600px) {
    /* -- Header Section CSS Start -- */

    .header-section-main {
      padding-top: 35px;
    }
    .header-menu ul li a:after {
      bottom: -60px;
    }

    /* -- Header Section CSS End -- */

    /* -- Banner Section CSS Start -- */

    .banner-left-vertical-main {
      margin-top: 0;
    }
    .banner-left-vertical-main h6 {
      letter-spacing: 16px;
    }
    .banner-left-vertical-main img {
      width: 60px;
      height: 60px;
    }
    .banner-content-main h5 {
      font-size: 16px;
      line-height: 20px;
    }
    .banner-section-loop .banner-content-main h1 {
      font-size: 70px;
      line-height: 80px;
    }
    .banner-section-loop .banner-content-main h1 {
      padding-bottom: 24px;
    }
    .controller-box-img {
      width: 45px;
      height: 45px;
    }
    .controller-box-section {
      padding: 15px;
      margin-bottom: 12px;
    }
    .controller-img {
      padding: 14px;
    }
    .controller-icons-main {
      gap: 16px;
      padding-bottom: 35px;
    }

    .controller-right-icons-main img {
      width: 55px;
      height: 55px;
      margin-bottom: 25px;
    }

    /* -- Banner Section CSS End -- */
  }

  @media only screen and (min-width: 1024px) and (max-width: 1500px) and (max-height: 620px) {
    /* -- Banner Section CSS Start -- */

    .banner-main-img {
      max-height: 620px;
    }
    .banner-left-vertical-main h6 {
      letter-spacing: 10px;
    }

    /* -- Banner Section CSS End -- */
  }

  @media only screen and (max-width: 1300px) {
    /* -- Header Section CSS Start -- */

    .header-section-main {
      padding-top: 30px;
    }
    .header-menu ul li a:after {
      bottom: -56px;
    }
    .header-menu ul {
      gap: 70px;
    }
    .custom-container {
      padding: 0 18px;
    }

    /* -- Header Section CSS End -- */

    /* -- Banner Section CSS Start -- */

    .controller-right-icons-main {
      width: calc(10% - 8px);
      padding-right: 8px;
    }
    .banner-section-loop .banner-content-main h1 {
      font-size: 55px;
      line-height: 65px;
      padding-bottom: 20px;
    }
    .controller-box-img {
      width: 40px;
      height: 40px;
    }
    .controller-box-section {
      padding: 12px;
      margin-bottom: 10px;
    }
    .banner-content-main h5 {
      font-size: 14px;
      line-height: 18px;
    }
    .controller-img {
      padding: 12px;
    }
    .controller-icons-main {
      gap: 14px;
      padding-bottom: 30px;
    }

    .controller-right-icons-main img {
      width: 50px;
      height: 50px;
      margin-bottom: 20px;
    }
    .banner-main-img .main-img {
      padding-top: 100%;
    }

    /* -- Banner Section CSS End -- */
  }
</style>
<script setup>
  import { useHead } from "@unhead/vue";
  import { ref, onMounted, onBeforeUnmount, computed } from "vue";

  import { useI18n } from "vue-i18n";
  import { useRouter } from "vue-router";
  import { useJoinRoomStore } from "@/stores/joinRoom";
  // import { useAuthStore } from "@/stores/auth";
  import { useGlobalStore } from "@/stores/global";

  // const show = ref(false);
  const global = useGlobalStore();
  // const auth = useAuthStore();
  const router = useRouter();

  const goToRoomActive = ref(false);
  const joinRoom = useJoinRoomStore();

  const socket = joinRoom.socket;
  const { t } = useI18n();

  const roomList = ref([]);
  const difficulties = ref([
    {
      value: 1,
      checked:
        global.difficulty == 1 || global.difficulty == 3 || global.difficulty == 5 || global.difficulty == 7
          ? true
          : false,
      label: computed(() => t("home.setup.difficulty.easy")),
      icon: `M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm0 192a88 88 0 1 1 88-88a88.1 88.1 0 0 1-88 88ZM80 108a12 12 0 1 1 12 12a12 12 0 0 1-12-12Zm96 0a12 12 0 1 1-12-12a12 12 0 0 1 12 12Zm-1.07 48c-10.29 17.79-27.4 28-46.93 28s-36.63-10.2-46.92-28a8 8 0 1 1 13.84-8c7.47 12.91 19.21 20 33.08 20s25.61-7.1 33.07-20a8 8 0 0 1 13.86 8Z`,
    },
    {
      value: 2,
      checked:
        global.difficulty == 2 || global.difficulty == 3 || global.difficulty == 6 || global.difficulty == 7
          ? true
          : false,
      label: computed(() => t("home.setup.difficulty.medium")),
      icon: `M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm0 192a88 88 0 1 1 88-88a88.1 88.1 0 0 1-88 88Zm53.66-53.66a8 8 0 0 1-11.32 11.32L160 163.31l-10.34 10.35a8 8 0 0 1-11.32 0L128 163.31l-10.34 10.35a8 8 0 0 1-11.32 0L96 163.31l-10.34 10.35a8 8 0 0 1-11.32-11.32l16-16a8 8 0 0 1 11.32 0L112 156.69l10.34-10.35a8 8 0 0 1 11.32 0L144 156.69l10.34-10.35a8 8 0 0 1 11.32 0ZM80 108a12 12 0 1 1 12 12a12 12 0 0 1-12-12Zm72 0a12 12 0 1 1 12 12a12 12 0 0 1-12-12Z`,
    },
    {
      value: 4,
      checked:
        global.difficulty == 4 || global.difficulty == 5 || global.difficulty == 6 || global.difficulty == 7
          ? true
          : false,
      label: computed(() => t("home.setup.difficulty.hard")),
      icon: `M92 152a12 12 0 1 1 12-12a12 12 0 0 1-12 12Zm72-24a12 12 0 1 0 12 12a12 12 0 0 0-12-12Zm68 0A104 104 0 1 1 128 24a104.11 104.11 0 0 1 104 104Zm-16 0a88 88 0 1 0-88 88a88.1 88.1 0 0 0 88-88Zm-44.44-46.66L128 110.39l-43.56-29a8 8 0 1 0-8.88 13.32l48 32a8 8 0 0 0 8.88 0l48-32a8 8 0 0 0-8.88-13.32Zm-15.13 96C148 171.73 139.94 168 128 168s-20 3.73-28.43 9.34a8 8 0 0 0 8.86 13.32C114.93 186.34 120 184 128 184s13.07 2.34 19.57 6.66a8 8 0 1 0 8.86-13.32Z`,
    },
  ]);

  const mediaTypes = ref([
    {
      value: 1,
      checked: global.type == 1 || global.type == 3 || global.type == 5 || global.type == 7 ? true : false,
      label: computed(() => t("home.setup.media_type.openings")),
      icon: `M23 3H1V1h22v2M2 22h4c0-3-2-5-2-5c6-4 7-13 7-13H2v18M22 4h-9s1 9 7 13c0 0-2 2-2 5h4V4Z`,
      viewBox: "0 0 24 24",
    },
    {
      value: 2,
      checked: global.type == 2 || global.type == 3 || global.type == 6 || global.type == 7 ? true : false,
      label: computed(() => t("home.setup.media_type.endings")),
      icon: `M23 3H1V1h22v2M2 22h9V4H2v18M22 4h-9v18h9V4Z`,
      viewBox: "0 0 24 24",
    },
    {
      value: 4,
      checked: global.type == 4 || global.type == 5 || global.type == 6 || global.type == 7 ? true : false,
      label: computed(() => t("home.setup.media_type.ost")),
      icon: `M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm0 192a88 88 0 1 1 88-88a88.1 88.1 0 0 1-88 88Zm0-144a56.06 56.06 0 0 0-56 56a8 8 0 0 1-16 0a72.08 72.08 0 0 1 72-72a8 8 0 0 1 0 16Zm72 56a72.08 72.08 0 0 1-72 72a8 8 0 0 1 0-16a56.06 56.06 0 0 0 56-56a8 8 0 0 1 16 0Zm-40 0a32 32 0 1 0-32 32a32 32 0 0 0 32-32Zm-48 0a16 16 0 1 1 16 16a16 16 0 0 1-16-16Z`,
      viewBox: "0 0 256 256",
    },
  ]);

  const updateDifficulty = () => {
    let total = 0;
    for (const difficulty of difficulties.value) {
      if (difficulty.checked) {
        total += difficulty.value;
      }
    }
    global.difficulty = total;
  };
  const updateMediaType = () => {
    let total = 0;
    for (const mediaType of mediaTypes.value) {
      if (mediaType.checked) {
        total += mediaType.value;
      }
    }
    global.type = total;
  };

  const goToRoom = () => {
    global.back = false;
    goToRoomActive.value = true;
    socket.emit("goToRoomSetup", {
      answering: global.answering,
      difficulty: global.difficulty,
      songsNumber: global.number,
      mediaType: global.type,
      guessTime: global.time,
      songsSelection: global.custom,
      privateRoom: global.privateRoom,
      n: 0,
    });
    // setTimeout(function () {
    //   document.getElementById("router").classList.add("line");
    // }, 200);
    document.getElementById("router").classList.add("test");
    document.getElementById("router").classList.add("active");
    // document.getElementById("back").classList.remove("active");
    // document.getElementById("logo").classList.add("active");
    setTimeout(function () {
      router.push({ name: "testroom", query: { id: "data" } });
    }, 200);
    setTimeout(function () {
      // document.getElementById("controller-play").classList.remove("active");
      // document.getElementById("banner-content-main").classList.remove("active");
      // document.getElementById("section-home").classList.remove("active");
      // document.getElementById("banner-section-second").classList.remove("active");
      // document.getElementById("play").textContent = "Jouer";
      // document.getElementById("amq").textContent = "BLIND TEST ANIME";
      document.getElementById("router").classList.remove("active");
      document.getElementById("router").classList.add("scale");
      document.getElementById("router").classList.remove("test");
    }, 250);
    // setTimeout(function () {
    //   document.getElementById("router").classList.remove("line");
    // }, 500);
    // setTimeout(function () {
    //   document.getElementById("banner-left-vertical-main").classList.remove("active");
    //   document.getElementById("router").classList.remove("active");
    //   document.getElementById("logo").classList.remove("active");
    // }, 700);
    setTimeout(function () {
      document.getElementById("router").classList.remove("scale");
    }, 300);
  };

  socket.on("goToRoom", (data) => {
    global.back = false;
    // setTimeout(function () {
    //   document.getElementById("router").classList.remove("line");
    // }, 500);
    // setTimeout(function () {
    //   document.getElementById("router").classList.remove("active");
    //   document.getElementById("logo").classList.remove("active");
    // }, 700);
    router.push({ name: "room", query: { id: data } });
  });
  socket.on("allRoom", function (roomListValue) {
    roomList.value = roomListValue;
  });
  socket.on("allRoomUpdate", function (salle, roomListValue) {
    let indexObjetTrouve = roomList.value.findIndex((objet) => objet.id === salle);

    if (indexObjetTrouve !== -1) {
      roomList.value[indexObjetTrouve] = roomListValue;
    }
  });
  onBeforeUnmount(() => {
    socket.off();
  });
  useHead({
    script: [
      {
        src: "https://cdnjs.cloudflare.com/ajax/libs/gsap/3.12.2/gsap.min.js",
      },
      {
        src: "https://cdnjs.cloudflare.com/ajax/libs/parallax/3.1.0/parallax.min.js",
      },
    ],
  });
  onMounted(async () => {
    setTimeout(async function () {
      // body

      // Character Image Js
      var scene = document.querySelectorAll(".scene");
      scene.forEach(function (el) {
        // eslint-disable-next-line
        var parallax = new Parallax(el);
        el.classList.remove("opacity-0");
      });
    }, 300);
    socket.emit("allRoom");
    updateDifficulty();
    updateMediaType();
    global.pageLoad();
  });
  function play(toggle) {
    if (global.back) {
      goToRoom();
    } else {
      if (toggle) {
        global.back = true;
        document.getElementById("back").classList.add("active");
        document.getElementById("section-home").classList.add("active");
        document.getElementById("banner-left-vertical-main").classList.add("active");
        document.getElementById("banner-section-second").classList.add("active");
        document.getElementById("banner-content-main").classList.add("active");
        document.getElementById("controller-play").classList.add("active");

        setTimeout(function () {
          document.getElementById("play").textContent = "LANCER";
          document.getElementById("amq").textContent = "CREER UNE PARTIE";
        }, 100);
      } else {
        global.back = false;
        document.getElementById("back").classList.remove("active");
        document.getElementById("controller-play").classList.remove("active");
        document.getElementById("banner-content-main").classList.remove("active");
        document.getElementById("section-home").classList.remove("active");
        document.getElementById("banner-section-second").classList.remove("active");

        setTimeout(function () {
          document.getElementById("banner-left-vertical-main").classList.remove("active");
        }, 100);
        setTimeout(function () {
          document.getElementById("play").textContent = "Jouer";
          document.getElementById("amq").textContent = "BLIND TEST ANIME";
        }, 50);
      }
    }
  }
</script>
