<template>
  <div v-if="$route.path != '/maintenance'" class="progressbar">
    <div id="color" class="color"></div>
  </div>
  <!-- valorant -->
  <HeaderMenu v-if="$route.path != '/maintenance'" />
  <!-- <HeaderMenuTest v-if="$route.path != '/maintenance'" /> -->
  <div class="flex justify-center">
    <!-- valorant -->
    <div class="max-w-[1200px] w-full sm:mx-8 calc4rem">
      <!-- <div id="router"> -->
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </div>
  <HeaderBottomMenu
    v-if="
      $route.path != '/maintenance' &&
      $route.path != '/admin/tiktok' &&
      $route.path != '/admin/tiktok/character' &&
      $route.path != '/admin/tiktok/easy' &&
      $route.path != '/admin/tiktok/v2'
    " />
</template>

<script setup>
  // valorant
  import HeaderMenu from "./components/HeaderMenu.vue";
  // import HeaderMenuTest from "./components/HeaderMenuTest.vue";
  import HeaderBottomMenu from "./components/HeaderBottomMenu.vue";
  import { onMounted } from "vue";

  onMounted(async () => {
    window.addEventListener("keydown", prevDefArrow);
  });
  const prevDefArrow = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };
</script>

<style>
  /* valorant  */
  /* :root {
    --white: #ffffff;
    --black: #000000;
    --light-t1: #ccc4ed;
    --text-color-1: rgba(187, 177, 231, 0.62);
    --text-color-2: rgba(154, 119, 35, 1);
    --text-color-3: rgba(3, 140, 54, 1);
    --text-color-4: #a395df;
    --text-color-5: #9f91de;
    --text-color-6: #826dff;
    --text-color-7: #9a7723;
    --text-color-8: #eaa742;
    --text-color-9: #e9a742;
    --text-color-10: #c4893b;
    --text-color-11: #ffe0b7;
    --text-color-12: #dfa650;
    --text-color-13: #ffca79;
    --border-color-2: #6b5536;
    --text-color-14: #038c36;
    --text-color-15: #04bc49;
    --text-color-16: #01ab3f;
    --text-color-17: #d4ffe4;
  }
  .valo {
    font-family: "VALORANT";
  }
  .main {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    font-style: normal;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: auto;
    color: var(--text-color-1);
  }
  #router {
    transition: all 0.2s, opacity 0.2s;
    width: 100vw;
    height: 100vh;
  }
  #router > div {
    transition: all 0.3s;
  }
  #router.active {
    scale: 8;
  }
  #router.scale {
    scale: 0.5;
    transition: 0s !important;
  }
  #router.test > div {
    opacity: 0;
    transition: all 0.2s;
  }
  #router.line {
    transition: all 0.4s;
    width: 33.333%;
    border: 5px solid var(--light-t1);
    background: var(--light-t1);
  } */

  /* fin valorant  */

  /* .page-enter-active,
  .page-leave-active {
    transition: all 0.2s;
  }
  .page-enter-from,
  .page-leave-to {
    opacity: 0;
    transform: scale(1.1);
  } */
  /* valorant */
  body :not(.animeScroll)::-webkit-scrollbar {
    width: 15px;
  }

  body :not(.animeScroll)::-webkit-scrollbar-track {
    background: transparant;
  }

  body :not(.animeScroll)::-webkit-scrollbar-thumb {
    background: #008ca8;
    border-radius: 100px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }
  /* html {
    overflow: hidden;
  }
  body::-webkit-scrollbar {
    width: 0px;
  } */

  body {
    /* background: #111827; */
    /* background-color: #222c48; */
    background-color: #020420;
    color: white;
    min-height: 100vh;
    /* background: url(/global/bg_avatar.png), #030712;
     */
    background-size: cover;
  }
  /* valorant */
  body > div {
    min-height: 100vh;
    background: radial-gradient(
        66.5% 221.21% at 136.03% -61.44%,
        rgba(245, 79, 103, 0.4) 0%,
        rgba(161, 155, 217, 0) 92.09%
      ),
      radial-gradient(93.54% 126.91% at 29.15% -56.29%, rgb(0 140 168 / 70%) 0%, rgba(233, 69, 96, 0) 92.09%),
      radial-gradient(86.19% 101.84% at -17.13% -24.8%, rgb(0 140 168 / 70%) 0%, rgba(0, 162, 254, 0) 92.09%);
    background-attachment: fixed;
  }
  /* valorant */
  /* body {
    background: linear-gradient(45deg, #2fddde 0%, #4f53af 30%, #344673 65%, #1e3344 100%);
  } */
  .home-card {
    transition: 0.3s;
  }
  .home-card:hover {
    box-shadow: 0px 15px 20px rgb(0 140 168 / 40%);
    transform: translateY(-7px);
  }
  .button-reverse {
    background: linear-gradient(to right, #008ca8 0%, #00fddc 50%, #008ca8 100%) !important;
    background-position: left center;
    transition: 0.4s;
    background-size: 200% auto !important;
  }
  .button-reverse:hover {
    background-position: right center !important;
  }
  .shadow-3xl {
    box-shadow: 0 4px 8px 2px rgba(0, 5, 13, 0.5);
  }
  body .scrollbar-none {
    scrollbar-width: none;
  }
  body .scrollbar-none::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 639px) {
    .calc2rem {
      width: calc(100% - 2rem) !important;
    }
  }
  @media (min-width: 640px) {
    .sm\:shadow-3xl {
      box-shadow: 0 4px 8px 2px rgba(0, 5, 13, 0.5);
    }
    .calc4rem {
      width: calc(100% - 4rem) !important;
    }
  }
  @media (min-width: 768px) {
    .md\:shadow-3xl {
      box-shadow: 0 4px 8px 2px rgba(0, 5, 13, 0.5);
    }
  }
  .bg-first {
    background: #020420 !important;
  }
  .bg-second {
    background-color: #0f112b;
  }
  .bg-linear {
    background: linear-gradient(to right, #008ca8 0%, #00fddc 100%);
  }
  .bg-third {
    background-color: rgb(255 255 255 / 5%);
  }
  .v-tooltip > .v-overlay__content {
    background-color: #0f112b;
    box-shadow: 0 4px 8px 2px rgba(0, 5, 13, 0.5);
  }
  .v-skeleton-loader__image {
    height: 100%;
  }
  .selected {
    background: #1c1e33;
  }
  .font-medium {
    font-weight: 600 !important;
  }
  .v-img__placeholder .v-sheet {
    background: #020420;
  }
  .shadow-base {
    box-shadow: -5px -5px 5px -5px #008ca8, 5px 5px 5px -5px #008ca8, -7px -7px 10px -5px transparent,
      7px 7px 10px -5px transparent, 0 0 5px 0px rgba(255, 255, 255, 0), 0 55px 35px -20px rgba(0, 0, 0, 0.5);
    filter: drop-shadow(black 2px 4px 6px);
    padding: 3px 5px 0;
  }
  .menu-anime {
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 300ms;
    cursor: pointer;
    border-radius: 9999px;
    min-width: 46px;
  }
  .menu-anime .line1,
  .menu-anime .line2 {
    width: 16px;
    height: 2px;
    position: absolute;
    background: white;
    border-radius: 9999px;
  }
  .menu-anime .line2 {
    rotate: 90deg;
  }
  .modal-menu-anime button.active {
    box-shadow: #fff 0 0 16px 0;
    outline: 1px solid;
  }
  .progressbar {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 10001;
  }

  .progressbar .color {
    animation: ease 1.5s normal;
    border-radius: 100px;
    background: #008ca8;
    height: 3px;
    transition: all 1.5s, visibility 0s;
    width: 0;
    transition-timing-function: ease;
  }
</style>
