import { createRouter, createWebHistory } from "vue-router";
import { useJoinRoomStore } from "./stores/joinRoom";
import HomeGame from "./components/HomeGame.vue";
import RoomGame from "./components/RoomGame.vue";
import PageNotFound from "./components/PageNotFound.vue";
import InfoAnime from "./components/InfoAnime.vue";
import BattlePass from "./components/BattlePass.vue";
import AvatarView from "./components/AvatarView.vue";
import AnimeView from "./components/AnimeView.vue";
import UserProfile from "./components/UserProfile.vue";
import ProfileView from "./components/ProfileView.vue";
import MaintenanceView from "./components/MaintenanceView.vue";
import TestView from "./components/TestView.vue";
import TikTokMenu from "./components/TikTokMenu.vue";
import TikTok from "./components/TikTok.vue";
import Admin from "./components/AdminMenu.vue";
import ResetPassword from "./components/ResetPassword.vue";
import ModoSongManage from "./components/ModoSongManage.vue";
import AdminAnimeManage from "./components/AdminAnimeManage.vue";
import PrivacyPolicy from "./components/PrivacyPolicy.vue";
import { useAuthStore } from "@/stores/auth";
import { useCookies } from "@vueuse/integrations/useCookies";
import { useFetch } from "@vueuse/core";
import SettingsView from "./components/SettingsView.vue";
import TikTokCharacter from "./components/TikTokCharacter.vue";
import TikTokEasy from "./components/TikTokEasy.vue";
import TiktokThumbnail from "./components/TiktokThumbnail.vue";
import AdminAddSongs from "./components/AdminAddSongs.vue";
import AdminAddScreenshots from "./components/AdminAddScreenshots.vue";
import TikTokV2 from "./components/TikTokV2.vue";
import TestRoomGame from "./components/TestRoomGame.vue";
import TestHome from "./components/TestHome.vue";
import TestBattlePass from "./components/TestBattlePass.vue";
import TiktokPixelAnime from "./components/TiktokPixelAnime.vue";
import TiktokPixelCharacter from "./components/TiktokPixelCharacter.vue";
import TiktokRandomCharacter from "./components/TiktokRandomCharacter.vue";
import TiktokScreenshot from "./components/TiktokScreenshot.vue";
import TiktokCut from "./components/TiktokCut.vue";
import TiktokManage from "./components/TiktokManage.vue";
import TiktokVoice from "./components/TiktokVoice.vue";
import TiktokPixelVideo from "./components/TiktokPixelVideo.vue";

const routes = [
  { path: "/", component: HomeGame, name: "home" },
  { path: "/room", component: RoomGame, name: "room" },
  { path: "/testroom", component: TestRoomGame, beforeEnter: checkAdminRights, name: "testroom" },
  { path: "/testhome", component: TestHome, name: "testhome" },
  { path: "/battle-pass", component: BattlePass, name: "battlepass" },
  { path: "/testbattle-pass", component: TestBattlePass, beforeEnter: checkAdminRights, name: "testbattlepass" },
  { path: "/avatar", component: AvatarView, beforeEnter: checkConnected, name: "avatar" },
  { path: "/anime", component: AnimeView, name: "anime" },
  { path: "/@:username", component: UserProfile, name: "user" },
  { path: "/profile", component: ProfileView, beforeEnter: checkConnected, name: "profile" },
  { path: "/settings", component: SettingsView, beforeEnter: checkConnected, name: "settings" },
  { path: "/test", component: TestView, beforeEnter: checkAdminRights, name: "test" },
  {
    path: "/admin/tiktok/pixel/anime",
    component: TiktokPixelAnime,
    beforeEnter: checkAdminRights,
    name: "TiktokPixelAnime",
  },
  {
    path: "/admin/tiktok/voice",
    component: TiktokVoice,
    beforeEnter: checkAdminRights,
    name: "TiktokVoice",
  },
  {
    path: "/admin/tiktok/manage",
    component: TiktokManage,
    beforeEnter: checkAdminRights,
    name: "TiktokManage",
  },
  {
    path: "/admin/tiktok/cut",
    component: TiktokCut,
    beforeEnter: checkAdminRights,
    name: "TiktokCut",
  },
  {
    path: "/admin/tiktok/pixel/character",
    component: TiktokPixelCharacter,
    beforeEnter: checkAdminRights,
    name: "TiktokPixelCharacter",
  },
  {
    path: "/admin/tiktok/character/random",
    component: TiktokRandomCharacter,
    beforeEnter: checkAdminRights,
    name: "TiktokRandomCharacter",
  },
  {
    path: "/admin/tiktok/screenshot",
    component: TiktokScreenshot,
    beforeEnter: checkAdminRights,
    name: "TiktokScreenshot",
  },
  {
    path: "/admin/tiktok/pixel/video",
    component: TiktokPixelVideo,
    beforeEnter: checkAdminRights,
    name: "TiktokPixelVideo",
  },
  { path: "/admin/tiktok/menu", component: TikTokMenu, beforeEnter: checkAdminRights, name: "tiktokmenu" },
  { path: "/admin/song/add", component: AdminAddSongs, beforeEnter: checkAdminRights, name: "adminadd" },
  {
    path: "/admin/anime/screenshots/add",
    component: AdminAddScreenshots,
    beforeEnter: checkAdminRights,
    name: "screenshotsadd",
  },
  {
    path: "/admin/tiktok/thumbnail",
    component: TiktokThumbnail,
    beforeEnter: checkAdminRights,
    name: "tiktokthumbnail",
  },
  { path: "/admin/tiktok", component: TikTok, beforeEnter: checkAdminRights, name: "tiktok" },
  { path: "/admin/tiktok/v2", component: TikTokV2, beforeEnter: checkAdminRights, name: "tiktokv2" },
  {
    path: "/admin/tiktok/character",
    component: TikTokCharacter,
    beforeEnter: checkAdminRights,
    name: "tiktokCharacter",
  },
  {
    path: "/admin/tiktok/easy",
    component: TikTokEasy,
    beforeEnter: checkAdminRights,
    name: "tiktokEasy",
  },
  { path: "/modo/song/manage", component: ModoSongManage, beforeEnter: checkAdminRights, name: "songmanage" },
  { path: "/admin", component: Admin, beforeEnter: checkAdminRights, name: "admin" },
  { path: "/admin/anime/manage", component: AdminAnimeManage, beforeEnter: checkAdminRights, name: "adminanime" },
  { path: "/reset-password", component: ResetPassword, name: "reset" },
  { path: "/privacy", component: PrivacyPolicy, name: "privacy" },
  {
    path: "/anime/:title",
    name: "infos",
    component: InfoAnime,
  },
  {
    path: "/maintenance",
    component: MaintenanceView,
    name: "maintenance",
  },
  { path: "/404", component: PageNotFound, name: "404" },
  { path: "/:pathMatch(.*)", component: PageNotFound, name: "PageNotFound" },
];
const path = window.location.pathname;
const match = path.match(/^\/(en|fr)\//);
let getLocaleFromURL;
if (match) {
  getLocaleFromURL = match[0] == "/fr/" ? "fr" : match[0] == "/en/" ? "en" : null;
}
const userLang = navigator.language || navigator.userLanguage;
const defaultLang = getLocaleFromURL
  ? getLocaleFromURL
  : localStorage.getItem("lang")
  ? localStorage.getItem("lang")
  : userLang.startsWith("fr")
  ? "fr"
  : "en";
const router = createRouter({
  history: createWebHistory(defaultLang),
  routes,
});
function checkConnected(to, from, next) {
  const auth = useAuthStore();
  if (auth.user.role != "guest") {
    next();
  } else {
    next({ path: "/404" });
  }
}
function checkAdminRights(to, from, next) {
  const auth = useAuthStore();
  if (auth.user.role == "admin") {
    next();
  } else {
    next({ path: "/404" });
  }
}
router.beforeEach(async (to, from) => {
  const auth = useAuthStore();
  const joinRoom = useJoinRoomStore();
  window.scrollTo(0, 0);
  document.getElementById("color").style.visibility = "visible";
  document.getElementById("color").style.setProperty("width", "80%");
  if (!auth.user.idUser) {
    const { data: response } = await useFetch("/api/auth/reconnect", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        authToken: useCookies().get("authToken"),
        guest: useCookies().get("guest"),
      }),
    });
    const userInfos = JSON.parse(response.value);
    if (userInfos?.[0]?.id) {
      auth.user.role = userInfos[0].type;
      auth.user.avatar = userInfos[0].avatar;
      if (!auth.user.avatar) {
        auth.user.avatar = "avatar.png";
      }
      auth.user.idUser = userInfos[0].id;
      auth.user.lvl = userInfos[0].lvl;
      auth.user.since = userInfos[0].since;
      auth.user.username = userInfos[0].username;
    } else if (useCookies().get("guest")) {
      auth.user.role = "guest";
      auth.user.idUser = useCookies().get("guest");
      auth.user.lvl = 0;
    } else {
      auth.user.role = "guest";
      auth.user.idUser = userInfos?.[0];
      auth.user.lvl = 0;
    }
  }

  if (
    (to.path.includes("/room") && to.query.id) ||
    (to.path.includes("/admin/tiktok") && to.query.id) ||
    (to.path.includes("/admin/tiktok/character") && to.query.id)
  ) {
    joinRoom.joinRoom(to.query.id);
  } else if (
    from.path.includes("/room") ||
    from.path.includes("/admin/tiktok") ||
    from.path.includes("/admin/tiktok/character")
  ) {
    joinRoom.leaveRoom();
  }
  // if (
  //   !localStorage.getItem("test") &&
  //   !to.path.includes("/maintenance")
  // ) {
  //   next("/maintenance");
  // } else {
  //   next();
  // }
});
export default router;
